/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const DatePicker = React.forwardRef((props, ref) => {
  const DATE_PART_DAY = 0;
  const DATE_PART_MONTH = 1;
  const DATE_PART_YEAR = 2;

  const { label, handleUpdate, ...passedProps } = props;

  const sleep = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

  const handleFocus = (e) => {
    const currentDateValue = e.target.value;
    if (currentDateValue) {
      const dateParts = currentDateValue.split('.');
      if (dateParts && dateParts.length === 3) {
        const firstDatePartLength = dateParts[0].length;
        e.target.setSelectionRange(0, firstDatePartLength);
      }
    }
  };

  const handleKeyUp = async (e) => {
    e.persist();
    const currentDateValue = e.target.value;
    const { key } = e;

    let updateAmount = 0;
    let moveSelection = 0;
    if (key === 'ArrowUp') {
      updateAmount = 1;
    } else if (key === 'ArrowDown') {
      updateAmount = -1;
    } else if (key === 'ArrowRight') {
      moveSelection = 1;
    } else if (key === 'ArrowLeft') {
      moveSelection = -1;
    } else {
      await sleep(100);
    }

    // Which part of date is selected?
    const cursorPos = e.target.selectionStart;
    const dateParts = currentDateValue.split('.');
    let partSelected = (dateParts.reduce((acc, item, index) => {
      if (cursorPos >= acc.startPos && cursorPos <= acc.startPos + item.length) {
        acc.match = index;
      }
      acc.startPos = acc.startPos + item.length + 1;
      return acc;
    }, {
      startPos: 0,
      match: 0,
    }).match + moveSelection) % 3;
    if (partSelected === -1) {
      partSelected = 2;
    }

    // Update date
    let updatedDate = currentDateValue;
    if (partSelected === DATE_PART_DAY) {
      updatedDate = moment(currentDateValue, 'D.M.YYYY').add(updateAmount, 'days').format('D.M.YYYY');
    } else if (partSelected === DATE_PART_MONTH) {
      updatedDate = moment(currentDateValue, 'D.M.YYYY').add(updateAmount, 'months').format('D.M.YYYY');
    } else if (partSelected === DATE_PART_YEAR) {
      updatedDate = moment(currentDateValue, 'D.M.YYYY').add(updateAmount, 'years').format('D.M.YYYY');
    }
    e.target.value = updatedDate;
    handleUpdate(updatedDate);

    // Update selection
    const updatedDateParts = updatedDate.split('.');
    const selectionStartPos = updatedDateParts.reduce((acc, item, index) => {
      if (index < partSelected) {
        return acc + item.length + 1;
      }
      return acc;
    }, 0);
    const selectionEndPos = selectionStartPos + updatedDateParts[partSelected].length;
    e.target.setSelectionRange(selectionStartPos, selectionEndPos);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      e.preventDefault();
    }
  };

  return (
    <input
      {...passedProps}
      ref={ref}
      onFocus={handleFocus}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
    />
  );
});

DatePicker.propTypes = {
  label: PropTypes.string,
  handleUpdate: PropTypes.func,
};

DatePicker.defaultProps = {
  label: '',
  handleUpdate: () => {},
};

export default DatePicker;
