/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import styled from 'styled-components';
import { FaSpinner } from 'react-icons/fa';
import {
  transactionApiUrl,
  TRANSACTION_TYPE_POTRAVINY,
  TRANSACTION_TYPE_NAFTA,
  EXPIRED_TOKEN_MESSAGE,
} from '../config';

const FilterWrapper = styled.div`
  width: 80%;
  margin-top: 40px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
`;

const StyledLabel = styled.label`
  margin-right: 20px;
`;

const StyledSelect = styled.select`
  background: #fff
  color: #444;
  padding-top: 8px;
  padding-right: 15px;
  padding-bottom: 8px;
  padding-left: 15px;
  margin-left: 5px;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 80%;
  margin: auto;
`;

const StyledTd = styled.td`
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  text-align: left;
`;

const StyledTh = styled.th`
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  text-align: left;
  font-weight: bold;
`;

function TransactionsYearView({ idToken, expiredTokenHandler }) {
  const now = new Date();
  const currentMonth = (now.getMonth() + 1);
  const currentYear = now.getFullYear();
  const [year, setYear] = useState(currentYear);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [transactionsData, setTransactionsData] = useState(null);
  const [aggregatedData, setAggregatedData] = useState(null);

  const handleYearSelect = (e) => setYear(e.target.value);

  useEffect(() => {
    const groupItems = (items) => (
      items.reduce((acc, item) => ({
        ...acc,
        [item.transactionMonth]: {
          ...(acc[item.transactionMonth]),
          [item.transactionType]:
            acc[item.transactionMonth] && acc[item.transactionMonth][item.transactionType]
              ? (
                parseFloat(acc[item.transactionMonth][item.transactionType])
                + parseFloat(item.transactionAmount)
              ).toFixed(2)
              : parseFloat(item.transactionAmount).toFixed(2),
        },
      }), {})
    );

    const aggregateData = (grouppedItems) => {
      const numberOfMonths = Object.keys(grouppedItems).length === currentMonth
        ? Object.keys(grouppedItems).length - 1
        : Object.keys(grouppedItems).length;
      return {
        ...grouppedItems,
        sum: {
          [TRANSACTION_TYPE_POTRAVINY]: Object.keys(grouppedItems)
            .reduce((acc, month) => (
              acc + parseFloat(grouppedItems[month][TRANSACTION_TYPE_POTRAVINY])
            ), 0).toFixed(2),
          [TRANSACTION_TYPE_NAFTA]: Object.keys(grouppedItems)
            .reduce((acc, month) => (
              acc + parseFloat(grouppedItems[month][TRANSACTION_TYPE_NAFTA])
            ), 0).toFixed(2),
        },
        avg: {
          [TRANSACTION_TYPE_POTRAVINY]: (
            Object.keys(grouppedItems)
              .reduce((acc, month) => (
                year === currentYear && Number.parseInt(month, 10) === currentMonth
                  ? acc : acc + parseFloat(grouppedItems[month][TRANSACTION_TYPE_POTRAVINY])
              ), 0) / numberOfMonths
          ).toFixed(2),
          [TRANSACTION_TYPE_NAFTA]: (
            Object.keys(grouppedItems)
              .reduce((acc, month) => (
                year === currentYear && Number.parseInt(month, 10) === currentMonth
                  ? acc : acc + parseFloat(grouppedItems[month][TRANSACTION_TYPE_NAFTA])
              ), 0) / numberOfMonths
          ).toFixed(2),
        },
      };
    };

    const loadData = async () => {
      setTransactionsLoading(true);
      const url = `${transactionApiUrl}/by-date?year=${year}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: idToken,
      });
      const params = {
        method: 'GET',
        mode: 'cors',
        headers,
      };
      let transactionItems = [];
      try {
        const response = await fetch(url, params);
        transactionItems = await response.json();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error when fetching data', e);
        setTransactionsLoading(false);
      }

      if (transactionItems.message === EXPIRED_TOKEN_MESSAGE) {
        expiredTokenHandler();
        return;
      }

      const grouppedData = groupItems(transactionItems);
      setTransactionsData(grouppedData);
      const aggrData = aggregateData(grouppedData);
      setAggregatedData(aggrData);
      setTransactionsLoading(false);
    };
    loadData();
  }, [currentMonth, currentYear, year, idToken, expiredTokenHandler]);

  return (
    <>
      <FilterWrapper>
        <StyledLabel htmlFor="selectYear">
          Rok:
          <StyledSelect id="selectYear" onChange={handleYearSelect} defaultValue={year}>
            {
              [...Array(5).keys()].map((i) => (
                <option key={currentYear - i} value={`${currentYear - i}`}>
                  {currentYear - i}
                </option>
              ))
            }
          </StyledSelect>
        </StyledLabel>
      </FilterWrapper>

      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Měsíc</StyledTh>
            <StyledTh>Potraviny</StyledTh>
            <StyledTh>Nafta</StyledTh>
          </tr>
        </thead>
        {transactionsData && Object.keys(transactionsData).length
        && aggregatedData && Object.keys(aggregatedData) && (
          <tbody>
            {Object.keys(transactionsData).map((month) => (
              <tr key={month}>
                <StyledTh>{month}</StyledTh>
                <StyledTd>{transactionsData[month][TRANSACTION_TYPE_POTRAVINY]}</StyledTd>
                <StyledTd>{transactionsData[month][TRANSACTION_TYPE_NAFTA]}</StyledTd>
              </tr>
            ))}
            <tr>
              <StyledTh>
                Celkem:
              </StyledTh>
              <StyledTh>
                {aggregatedData.sum[TRANSACTION_TYPE_POTRAVINY]}
              </StyledTh>
              <StyledTh>
                {aggregatedData.sum[TRANSACTION_TYPE_NAFTA]}
              </StyledTh>
            </tr>
            <tr>
              <StyledTh>
                Průměr:
              </StyledTh>
              <StyledTh>
                {Number.isNaN(aggregatedData.avg[TRANSACTION_TYPE_POTRAVINY]) ? '-' : aggregatedData.avg[TRANSACTION_TYPE_POTRAVINY]}
              </StyledTh>
              <StyledTh>
                {Number.isNaN(aggregatedData.avg[TRANSACTION_TYPE_NAFTA]) ? '-' : aggregatedData.avg[TRANSACTION_TYPE_NAFTA]}
              </StyledTh>
            </tr>
          </tbody>
        )}
        {(!transactionsData || !Object.keys(transactionsData)) && !transactionsLoading && (
          <tbody>
            <tr>
              <StyledTd colSpan={3}>
                Nejsou k dispozici žádná data pro tento výběr.
              </StyledTd>
            </tr>
          </tbody>
        )}
        {(!transactionsData || !Object.keys(transactionsData)) && transactionsLoading && (
          <tbody>
            <tr>
              <StyledTd colSpan={3}>
                <FaSpinner className="fa-spin" />
              </StyledTd>
            </tr>
          </tbody>
        )}
      </StyledTable>
    </>
  );
}

TransactionsYearView.propTypes = {
  idToken: PropTypes.string,
  expiredTokenHandler: PropTypes.func,
};

TransactionsYearView.defaultProps = {
  idToken: '',
  expiredTokenHandler: () => {},
};

export default TransactionsYearView;
