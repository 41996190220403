import React, { useEffect, useState } from 'react';
import Login from './login';
import TransactionForm from './transactionForm';
import TransactionsList from './transactionsList';
import TransactionsYearView from './transactionsYearView';
import { ID_TOKEN_STORAGE_KEY } from '../config';

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : initialValue,
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

function App() {
  const [idToken, setIdToken] = useLocalStorage(ID_TOKEN_STORAGE_KEY, '');
  const [loginError, setLoginError] = useState('');

  const loginHandler = (loginResponse) => {
    setLoginError('');
    setIdToken('');
    if (loginResponse && loginResponse.idToken) {
      setIdToken(loginResponse.idToken);
    }
    if (loginResponse && loginResponse.error) {
      setLoginError(loginResponse.error);
    }
  };

  const expiredTokenHandler = () => {
    setIdToken('');
  };

  return (
    <>
      {!idToken && (
        <Login
          loginHandler={loginHandler}
          loginError={loginError}
        />
      )}
      {idToken && (
        <>
          <TransactionForm idToken={idToken} />
          <TransactionsList
            idToken={idToken}
            expiredTokenHandler={expiredTokenHandler}
          />
          <TransactionsYearView
            idToken={idToken}
            expiredTokenHandler={expiredTokenHandler}
          />
        </>
      )}
    </>
  );
}

export default App;
