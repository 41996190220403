/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import styled from 'styled-components';
import { FaSpinner } from 'react-icons/fa';
import {
  transactionApiUrl,
  transactionTypes,
  TRANSACTION_TYPE_POTRAVINY,
  EXPIRED_TOKEN_MESSAGE,
} from '../config';

const FilterWrapper = styled.div`
  width: 80%;
  margin-top: 40px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
`;

const StyledLabel = styled.label`
  margin-right: 20px;
`;

const StyledSelect = styled.select`
  background: #fff
  color: #444;
  padding-top: 8px;
  padding-right: 15px;
  padding-bottom: 8px;
  padding-left: 15px;
  margin-left: 5px;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 80%;
  margin: auto;
`;

const StyledTd = styled.td`
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  text-align: left;
`;

const StyledTh = styled.th`
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  text-align: left;
  font-weight: bold;
`;

function TransactionsList({ idToken, expiredTokenHandler }) {
  const FILTER_ALL_VALUE = 'all';
  const FILTER_ALL_LABEL = 'vše';
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  const [displayYear, setDisplayYear] = useState(currentYear);
  const [displayMonth, setDisplayMonth] = useState(currentMonth);
  const [transactionType, setTransactionType] = useState(FILTER_ALL_VALUE);
  const [transactions, setTransactions] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [displayTransactions, setDisplayTransactions] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const updateTotal = (transactionsToProcess) => {
    const total = transactionsToProcess
      .reduce((acc, transaction) => acc + parseFloat(transaction.transactionAmount), 0)
      .toFixed(2);
    setTotalPrice(total);
  };

  const getFilteredTransactions = (transactionsToFilter, type) => {
    if (type !== FILTER_ALL_VALUE) {
      return transactionsToFilter
        .filter((transaction) => transaction.transactionType === type);
    }
    return transactionsToFilter;
  };

  useEffect(() => {
    const loadTransactions = async (year, month) => {
      setDisplayTransactions([]);
      setTransactionsLoading(true);
      const url = `${transactionApiUrl}/by-date?year=${year}&month=${month}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: idToken,
      });
      const params = {
        method: 'GET',
        mode: 'cors',
        headers,
      };
      let transactionsData = [];
      try {
        const response = await fetch(url, params);
        transactionsData = await response.json();
        setTransactionsLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error when fetching data', e);
        setTransactionsLoading(false);
      }

      if (transactionsData.message === EXPIRED_TOKEN_MESSAGE) {
        expiredTokenHandler();
        return;
      }

      transactionsData
        .sort((a, b) => ((a.transactionDay > b.transactionDay) ? 1 : -1));
      setTransactions(transactionsData);
      const filteredTransactions = getFilteredTransactions(transactionsData, transactionType);
      setDisplayTransactions(filteredTransactions);
      updateTotal(filteredTransactions);
    };

    loadTransactions(displayYear, displayMonth);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayYear, displayMonth]);

  const handleMonthSelect = (e) => setDisplayMonth(e.target.value);
  const handleYearSelect = (e) => setDisplayYear(e.target.value);
  const handleTypeSelect = (e) => {
    const type = e.target.value;
    const filteredTransactions = getFilteredTransactions(transactions, type);
    setTransactionType(type);
    setDisplayTransactions(filteredTransactions);
    updateTotal(filteredTransactions);
  };

  return (
    <>
      <FilterWrapper>
        <StyledLabel htmlFor="selectYear">
          Rok:
          <StyledSelect id="selectYear" onChange={handleYearSelect} defaultValue={displayYear}>
            {
              [...Array(5).keys()].map((i) => (
                <option key={currentYear - i} value={`${currentYear - i}`}>
                  {currentYear - i}
                </option>
              ))
            }
          </StyledSelect>
        </StyledLabel>
        <StyledLabel htmlFor="selectMonth">
          Měsíc:
          <StyledSelect id="selectMonth" onChange={handleMonthSelect} defaultValue={displayMonth}>
            {
              [...Array(12).keys()].map((i) => (
                <option key={i + 1} value={`${i + 1}`}>
                  {i + 1}
                </option>
              ))
            }
          </StyledSelect>
        </StyledLabel>
        <StyledLabel htmlFor="selectType">
          Typ:
          <StyledSelect id="selectType" onChange={handleTypeSelect} defaultValue={FILTER_ALL_VALUE}>
            <option value={`${FILTER_ALL_VALUE}`}>{FILTER_ALL_LABEL}</option>
            {transactionTypes.map((type) => (
              <option key={type} value={`${type}`}>{type}</option>
            ))}
          </StyledSelect>
        </StyledLabel>
      </FilterWrapper>
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Datum</StyledTh>
            <StyledTh>Typ</StyledTh>
            <StyledTh>Obchod</StyledTh>
            {transactionType !== TRANSACTION_TYPE_POTRAVINY && (
              <>
                <StyledTh>Položka</StyledTh>
                <StyledTh>Množství</StyledTh>
                <StyledTh>Cena</StyledTh>
              </>
            )}
            <StyledTh>Cena celkem</StyledTh>
          </tr>
        </thead>
        {displayTransactions && displayTransactions.length > 0 && (
          <tbody>
            {displayTransactions.map((row) => (
              <tr key={row.id}>
                <StyledTd>{row.transactionDate}</StyledTd>
                <StyledTd>{row.transactionType}</StyledTd>
                <StyledTd>{row.shopName}</StyledTd>
                {transactionType !== TRANSACTION_TYPE_POTRAVINY && (
                  <>
                    <StyledTd>{row.itemName}</StyledTd>
                    <StyledTd>{row.itemPrice}</StyledTd>
                    <StyledTd>{row.itemAmount}</StyledTd>
                  </>
                )}
                <StyledTd>{row.transactionAmount}</StyledTd>
              </tr>
            ))}
            <tr>
              <StyledTh colSpan={transactionType === TRANSACTION_TYPE_POTRAVINY ? 4 : 7}>
                Celkem:&nbsp;
                {totalPrice}
              </StyledTh>
            </tr>
          </tbody>
        )}
        {displayTransactions && displayTransactions.length === 0 && !transactionsLoading && (
          <tbody>
            <tr>
              <StyledTd colSpan={transactionType === TRANSACTION_TYPE_POTRAVINY ? 4 : 7}>
                Nejsou k dispozici žádná data pro tento výběr.
              </StyledTd>
            </tr>
          </tbody>
        )}
        {displayTransactions && displayTransactions.length === 0 && transactionsLoading && (
          <tbody>
            <tr>
              <StyledTd colSpan={transactionType === TRANSACTION_TYPE_POTRAVINY ? 4 : 7}>
                <FaSpinner className="fa-spin" />
              </StyledTd>
            </tr>
          </tbody>
        )}
      </StyledTable>
    </>
  );
}

TransactionsList.propTypes = {
  idToken: PropTypes.string,
  expiredTokenHandler: PropTypes.func,
};

TransactionsList.defaultProps = {
  idToken: '',
  expiredTokenHandler: () => {},
};

export default TransactionsList;
