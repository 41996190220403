/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaSpinner } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { transactionApiUrl } from '../config';

const StyledInputControl = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const ErrorMessageWrapper = styled.span`
  color: red;
`;

const StyledSubmitButton = styled.button`
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const FormWrapper = styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
  margin: auto;
`;

function Login({ loginHandler, loginError }) {
  const [loginInProgress, setLoginInProgress] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmitLogin = async (data) => {
    setLoginInProgress(true);
    const loginResponse = await fetch(`${transactionApiUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((r) => r.json());
    setLoginInProgress(false);
    loginHandler(loginResponse);
  };

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onSubmitLogin)}>
        <label htmlFor="username">
          Uživatelské jméno:
          <StyledInputControl {...register('username')} id="username" />
        </label>
        <label htmlFor="password">
          Heslo:
          <StyledInputControl type="password" {...register('password')} id="password" />
        </label>
        <StyledSubmitButton type="submit" disabled={loginInProgress}>
          {loginInProgress ? <FaSpinner className="fa-spin" /> : 'Přihlásit se'}
        </StyledSubmitButton>
      </form>
      <div><ErrorMessageWrapper>{loginError}</ErrorMessageWrapper></div>
    </FormWrapper>
  );
}

Login.propTypes = {
  loginHandler: PropTypes.func,
  loginError: PropTypes.string,
};

Login.defaultProps = {
  loginHandler: () => {},
  loginError: '',
};

export default Login;
