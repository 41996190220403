/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AutocompleteInput = React.forwardRef((props, ref) => {
  const { options, handleUpdate, ...passedProps } = props;
  const [matchTimeout, setMatchTimeout] = useState(null);
  const handleKeyUp = (e) => {
    const { value } = e.target;
    const { key } = e;

    if (matchTimeout) {
      clearTimeout(matchTimeout);
    }

    if (value.length < 2 || key === 'Delete' || key === 'Backspace') {
      return;
    }

    let matchedOption;
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        matchedOption = options[i];
        break;
      }
    }

    if (matchedOption) {
      const autocompleteMatchTimeout = setTimeout(() => {
        handleUpdate(matchedOption);
      }, 500);
      setMatchTimeout(autocompleteMatchTimeout);
    }
  };

  const handleFocus = (e) => {
    const { value } = e.target;
    e.target.setSelectionRange(0, value.length);
  };

  return (
    <input
      {...passedProps}
      onKeyUp={handleKeyUp}
      onFocus={handleFocus}
      autoComplete="off"
      ref={ref}
    />
  );
});

export default AutocompleteInput;

AutocompleteInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  handleUpdate: PropTypes.func,
};

AutocompleteInput.defaultProps = {
  options: [],
  handleUpdate: () => {},
};
