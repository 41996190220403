/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import fetch from 'isomorphic-fetch';
import { FaRegCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import DatePicker from './datePicker';
import AutocompleteInput from './autocompleteInput';
import {
  TRANSACTION_TYPE_NAFTA,
  transactionTypes,
  transactionApiUrl,
} from '../config';

const StyledInputControl = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const StyledAutocomplete = styled(AutocompleteInput)`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const StyledSelectControl = styled.select`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const StyledSubmitButton = styled.button`
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const FormWrapper = styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
  margin: auto;
`;

const ErrorMessageWrapper = styled.span`
  color: red;
`;

const SuccessMessageWrapper = styled.span`
  color: green;
`;

function TransactionForm({ idToken }) {
  const mockedShopNameOptions = ['Albert', 'Billa', 'Globus', 'Kaufland', 'Lidl', 'Tesco', 'Benzina', 'OMV', 'MOL'];
  const mockedItemNameOptions = ['Verva Diesel', 'Maxx Motion Diesel', 'EVO Diesel', 'Efecta Diesel'];

  const [transactionError, setTransactionError] = useState(null);
  const [transactionSuccess, setTransactionSuccess] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    getValues,
    errors,
  } = useForm();

  const watchTransactionType = watch('transactionType');

  const firstFormElementRef = useRef();

  const focusFirstFormElement = () => {
    if (firstFormElementRef.current) {
      firstFormElementRef.current.focus();
    }
  };

  useLayoutEffect(() => {
    focusFirstFormElement();
  }, []);

  const updateTransactionAmount = () => {
    const values = getValues();
    const { itemPrice, itemAmount } = values;
    if (itemPrice && itemAmount) {
      setValue('transactionAmount', (itemPrice * itemAmount).toFixed(1));
    }
  };

  const uploadTransaction = async (transactionData) => {
    try {
      const response = await fetch(transactionApiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: idToken,
        },
        body: JSON.stringify(transactionData),
      });
      return response;
    } catch (e) {
      return false;
    }
  };

  const resetForm = () => {
    reset();
    setValue('transactionDate', moment().format('D.M.YYYY'));
    setValue('shopName', '');
    setValue('itemName', '');
    focusFirstFormElement();
  };

  const onSubmit = async (data) => {
    const date = data.transactionDate;
    const dateArray = date.split('.');
    const transactionData = {
      ...data,
      transactionDay: parseInt(dateArray[0], 10),
      transactionMonth: parseInt(dateArray[1], 10),
      transactionYear: parseInt(dateArray[2], 10),
    };
    const response = await uploadTransaction(transactionData);
    if (response && response.status === 200) {
      setTransactionSuccess('Uloženo');
      setTimeout(() => {
        setTransactionSuccess(null);
      }, 5000);
      resetForm();
    } else {
      setTransactionError('Chyba při ukládání trasakce. Zkuste to prosím znovu.');
    }
  };

  const displayValidationError = (message) => (
    <div>
      <ErrorMessageWrapper>{message}</ErrorMessageWrapper>
      <br />
      <br />
    </div>
  );

  return (
    <FormWrapper>
      <h2>Nová položka</h2>
      {transactionError && (
        <p>
          <ErrorMessageWrapper>
            <FaExclamationCircle />
            &nbsp;
            {transactionError}
          </ErrorMessageWrapper>
        </p>
      )}
      {transactionSuccess && (
        <p>
          <SuccessMessageWrapper>
            <FaRegCheckCircle />
            &nbsp;
            {transactionSuccess}
          </SuccessMessageWrapper>
        </p>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Datum:</label>
        <Controller
          render={({ field }) => (<StyledDatePicker ref={firstFormElementRef} {...field} />)}
          name="transactionDate"
          control={control}
          defaultValue={moment().format('D.M.YYYY')}
          handleUpdate={(updatedDate) => setValue('transactionDate', updatedDate)}
        />

        <div>
          <label>Typ:</label>
          <StyledSelectControl {...register('transactionType')}>
            {transactionTypes.map((transactionType) => (
              <option key={transactionType} value={transactionType}>
                {transactionType}
              </option>
            ))}
          </StyledSelectControl>
        </div>
        <div>
          <label>Obchod:</label>
          <Controller
            render={({ field }) => (
              <StyledAutocomplete
                options={mockedShopNameOptions}
                handleUpdate={(updatedValue) => setValue('shopName', updatedValue)}
                {...field}
              />
            )}
            defaultValue=""
            name="shopName"
            control={control}
            rules={{ required: true }}
          />
          {errors && errors.shopName && displayValidationError('Toto pole je povinne.')}
        </div>
        { watchTransactionType === TRANSACTION_TYPE_NAFTA && (
          <>
            <div>
              <label>Polozka:</label>
              <Controller
                render={({ field }) => (
                  <StyledAutocomplete
                    options={mockedItemNameOptions}
                    handleUpdate={(updatedValue) => setValue('itemName', updatedValue)}
                    {...field}
                  />
                )}
                defaultValue=""
                name="itemName"
                control={control}
              />
            </div>
            <div>
              <label>Cena za polozku:</label>
              <StyledInputControl {...register('itemPrice')} onChange={updateTransactionAmount} />
            </div>
            <div>
              <label>Mnozstvi:</label>
              <StyledInputControl {...register('itemAmount')} onChange={updateTransactionAmount} />
            </div>
          </>
        )}
        <div>
          <label>Cena celkem:</label>
          <StyledInputControl {...register('transactionAmount', { required: true })} />
          {errors && errors.shopName && displayValidationError('Toto pole je povinne.')}
        </div>
        <StyledSubmitButton type="submit">
          Uložit
        </StyledSubmitButton>
      </form>
    </FormWrapper>
  );
}

TransactionForm.propTypes = {
  idToken: PropTypes.string,
};

TransactionForm.defaultProps = {
  idToken: '',
};

export default TransactionForm;
